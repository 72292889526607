import { toggleNav } from "./toggleNav";
import { features, about, gallery, partners, getintouch } from "./constants";

export const autoNavScroll = () => {
  if (window.pageYOffset === 0) {
    toggleNav(about);
  } else if (window.pageYOffset > 400 && window.pageYOffset < 700) {
    toggleNav(features);
  } else if (window.pageYOffset > 700 && window.pageYOffset < 1450) {
    toggleNav(gallery);
  } else if (window.pageYOffset > 1976 && window.pageYOffset < 2760) {
    toggleNav(partners);
  } else if (window.pageYOffset > 2760) {
    toggleNav(getintouch);
  }
};
