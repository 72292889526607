export let nav = document.getElementById('navbar');
export let arrow = document.getElementById('scroll-arrow');
export let about = document.getElementById("toabout");
export let features = document.getElementById("tofeatures");
export let gallery = document.getElementById("togallery");
export let partners = document.getElementById("topartners");
export let getintouch = document.getElementById("togetintouch");
export var red = '#dd2228';
export var black = '#000000';
export let scrollDuration = 1000;
export var navItems = [
  about,
  features,
  gallery,
  partners,
  getintouch,
  arrow
];