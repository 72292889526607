import "../src/index.scss";
require("imports-loader?window.jQuery=jquery!../../../node_modules/slick-carousel/slick/slick.min.js");
require("imports-loader?window.jQuery=jquery!../../../node_modules/slick-carousel/slick/slick-theme.css");
require("imports-loader?window.jQuery=jquery!../../../node_modules/slick-carousel/slick/slick.css");

$(document).ready(function () {

    let slider =
        $(window).width() > 700
            ? document.getElementById("slider")
            : document.getElementById("nothing");

    $(slider).slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        infinite: true,
        cssEase: "linear",
        autoplay: true
    });
});
