import { toggleNav } from "./toggleNav";
import { navItems, scrollDuration } from "./constants";
export var navClick = () => {
  $(document).ready(function() {
    $(navItems[0]).on("click", function() {
      toggleNav(navItems[0]);
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#about").offset().top,
        },
        scrollDuration,
      );
    });

    $(navItems[1]).on("click", function() {
      toggleNav(navItems[1]);

      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#features").offset().top - 130,
        },
        scrollDuration,
      );
    });

    $(navItems[2]).on("click", function() {
      toggleNav(navItems[2]);

      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#gallery").offset().top - 130,
        },
        scrollDuration,
      );
    });

    $(navItems[3]).on("click", function() {
      toggleNav(navItems[3]);

      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#partners").offset().top - 130,
        },
        scrollDuration,
      );
    });

    $(navItems[4]).on("click", function() {
      toggleNav(navItems[4]);

      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#getintouch").offset().top - 130,
        },
        scrollDuration,
      );
    });

    $(navItems[5]).on("click", function() {
      toggleNav(navItems[4]);

      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#about").offset().top,
        },
        scrollDuration,
      );
    });
  });
};
