require("waypoints/lib/jquery.waypoints");
require("waypoints/lib/shortcuts/sticky");
import { navClick } from "./utils/navClick";
import { nav, arrow } from "./utils/constants";
import { autoNavScroll } from "./utils/autoNavScroll";

$(document).ready(function() {
  autoNavScroll();
  navClick();

  $("#navbarNav").click(() => {
    if ($(document).width() < 990) {
      document
        .getElementById("navbarNav")
        .setAttribute("data-target", "#navbarNav");
      document
        .getElementById("navbarNav")
        .setAttribute("data-toggle", "collapse");
    } else {
      document.getElementById("navbarNav").removeAttribute("data-target");
      document.getElementById("navbarNav").removeAttribute("data-toggle");
    }
  });

  $(window).scroll(function() {
    autoNavScroll();

    if (window.pageYOffset !== 0) {
      nav.style.boxShadow = "0 3px 40px 0 rgba(0, 0, 0, 0.06)";
      arrow.style.visibility = "visible";
    } else {
      nav.style.boxShadow = "unset";
      arrow.style.visibility = "hidden";
    }
  });
});
