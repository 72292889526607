import "../src/index.scss";

window.jQuery = window.$ = require("jquery");
import navscroll from "./navscroll";
import featuresslider from "./featuresslider";
import { $newRow } from "./utils/galleryRow";

import img1 from "../images/screenshots-gallery-1.png";
import img2 from "../images/screenshots-gallery-2.png";
import img3 from "../images/screenshots-gallery-3.png";
import img4 from "../images/screenshots-gallery-4.png";
import img5 from "../images/screenshots-gallery-5.png";
import img6 from "../images/screenshots-gallery-6.png";

$(document).ready(function () {
  try {
    $(".preloader").fadeOut("slow");
    document.getElementById("app-wrapper").style.height = "auto";
    let appGallery = document.getElementById("appgallery");
    let gallery = [img1, img2, img3, img4, img5, img6];
    setInterval(() => {
      $(`#screen-${0}`).css("opacity", `0.3`);

      $(".smartphone-noframe").hide(1000);
      let friest = gallery[0];
      gallery.splice(0, 1);
      gallery.push(friest);
      gallery.map((screen, i) => {
        if (i !== 0) {
          jQuery($newRow(`screen-${i}`, screen))
            .appendTo(appGallery)
            .show("slow");
        }
      });

      $(".screen").css("background-image", `url(${gallery[0]})`);
    }, 3000);
  } catch (e) {
    console.warn(e);
  }
});
